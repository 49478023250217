import * as Sentry from '@sentry/sveltekit';

const ENABLE_SENTRY_ON_DEV = false;

export function logError(error: unknown) {
	if (import.meta.env.MODE === 'production' || ENABLE_SENTRY_ON_DEV) {
		Sentry.captureException(error);
	}
	console.error(error);
}

export function logWarning(
	error: unknown,
	priority: Sentry.SeverityLevel = 'warning'
) {
	if (import.meta.env.MODE === 'production' || ENABLE_SENTRY_ON_DEV) {
		Sentry.withScope((scope) => {
			scope.setLevel(priority);
			Sentry.captureException(error);
		});
	}
	console.warn(error);
}
