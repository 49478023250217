import type {DefinitionHolder} from '@blg/api-definition';
import {type Writable, writable} from 'svelte/store';

interface DefinitionHolderStore extends Writable<DefinitionHolder> {
	setDefinitionHolder(definitionHolder: DefinitionHolder): void;
}

function createDefinitionHolder(): DefinitionHolderStore {
	const {subscribe, set, update} = writable<DefinitionHolder>();

	return {
		subscribe,
		set,
		update,
		setDefinitionHolder: (definitionHolder: DefinitionHolder) =>
			set(definitionHolder)
	};
}

export const defininitionHolderStore = createDefinitionHolder();
